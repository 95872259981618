import Moment from 'moment';
import 'moment/locale/de-ch';
import prismic from './config/prismic.config';

// Localize Moment.js
Moment.locale('de');

// Global config obj
const config = {
  // Pass-through Prismic configuration
  prismic: {
    apiEndpoint: prismic.apiEndpoint,
    accessToken: prismic.accessToken,
    linkResolver: prismic.linkResolver,
    htmlSerializer: prismic.htmlSerializer,
  },
};

export const prismicConfig = config.prismic;
export const mailchimpConfig = config.mailchimp;
