import classNames from 'classnames';
import { Carousel } from 'nuka-carousel';
import KeyVisual from '../KeyVisual/KeyVisual';
import './Carousel.css';

const DIRECTION = { previous: 'previous', next: 'next' };

/**
 * Main template
 * Renders multiple images with optional captions in a carousel component.
 */
const tmpl = ({ slides }) => {
  // Filter-out slides without image
  slides = slides.filter((slide) => slide.slide_image?.url);
  // Do not render Carousel component if no slides
  if (slides.length < 1) return null;
  // Render Carousel
  return (
    <>
      <Carousel autoplay wrapMode="wrap" showArrows="hover">
        {slides?.map((slide, index) => {
          return (
            <div key={index} className="kq-carousel__slide">
              <KeyVisual
                image={slide.slide_image}
                caption={slide.slide_caption}
              />
            </div>
          );
        })}
      </Carousel>
    </>
  );
};

export default tmpl;
